import ArrowSvg from '@components/common/ArrowSvg'
import { digitalVision } from '@utils/constants'
import { useState } from 'react'

export default function DigitalVisionMobile({ show }: { show: boolean }) {
  const [current, setCurrent] = useState<number | null>()
  return (
    <div className='flex flex-col justify-center items-start self-start gap-30px w-100%'>
      <img src="/layers/mobile-grid-layer.svg" alt="grid layer" className={'absolute right-0 top-7.6rem -z-1 transition-all duration-500 ease-in-out'} />
      {digitalVision.map((item, i) => {
        return (
          <div
            key={i}
            className={`flex flex-col transition-all duration-500 ease-in-out ${current === i ? 'max-h-400px' : 'max-h-35px'}`}>

            <div
              className='flex items-center '>
              <p className={`flex justify-center items-center font-Montserrat text-hex-445069 w-105px h-40px transition-all duration-500 ease-in-out ${current === i ? 'text-12px font-700' : 'text-10px font-500'} `}>{item.header}</p>
              <div
                onMouseEnter={() => {
                  setCurrent(i)
                }}
                onMouseLeave={() => {
                  setCurrent(null)
                }}
                className={`relative flex items-center ${item.delay} ${item.titleBackground} origin-left transition-all duration-1000 ease-in-out ${!show ? ' p-6px justify-center rounded-22px h-32px' : 'h-auto px-12px py-6px justify-between rounded-17px <lg:rounded-12px <md:rounded-10px <lg:px-9px <lg:py-5px'}`}
              >
                <p
                  className={`font-700 font-Montserrat text-hex-445069 text-9px overflow-hidden transition-all duration-1000 ease-in-out origin-left line-clamp-1 text-ellipsis  ${item.delay} ${!show ? 'max-w-0' : 'max-w-24rem  '}`}
                  style={{
                    willChange: 'max-width'
                  }}
                >
                  {item.title}
                </p>
                <span
                  className={` rounded-full bg-white <lg:w-24px <lg:h-24px <md:w-20px <md:h-20px transition-all duration-1000 ease-in-out ${item.delay} ${show ? 'ml-34px <lg:ml-13px' : 'ml-0px'}`}></span>
              </div>
            </div>
            <div
              className={`first-disc flex justify-center gap-6px relative ${item.descriptionStyles} ${current === i ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}`}
            >
              <ArrowSvg className={`${item.lottieStyles} !w-3rem !h-3rem <md:!w-2.5rem z-10  ${current === i ? 'opacity-100 scale-100 SVG' : 'opacity-0 scale-0 fill-none'} transition-all duration-500 ease-in-out`} />
              <div className={`flex items-end gap-6px ${current === i ? 'opacity-100 scale-100 ' : 'opacity-0 scale-0 '} transition-all duration-500 ease-in-out delay-500`}>
                <p
                  className="font-number text-64px font-400 text-hex-FFE1E1 <lg:text-48px"
                >
                  {item.number}
                </p>
                <p
                  className="rounded-22px bg-white shadow-[0px_0px_35.3124px_rgba(0,0,0,0.12)] p-16px text-hex-445069 text-16px font-400 font-Montserrat leading-15px w-380px <lg:text-12px <lg:rounded-17px <lg:p-12px <lg:w-290px"
                >
                  {item.description}
                </p>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
