import MaskedTitle from '@components/common/MaskedTitle'
import { useEffect, useRef, useState } from 'react'
import { breakpoints, digitalVision } from '@utils/constants'
import useBreakpoint from 'use-breakpoint'
import ArrowSvg from '@components/common/ArrowSvg'
import DigitalVisionMobile from './DigitalVisionMobile'

export default function DigitalVision() {
  const { breakpoint } = useBreakpoint(breakpoints, 'largeDesktop')
  const [show, setShow] = useState(false)
  const [current, setCurrent] = useState<number | null>()
  const containerRef = useRef(null)
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry!.isIntersecting) {
        setShow(true)
        observer.unobserve(containerRef.current!)
      }
    })
    observer.observe(containerRef.current!)
    return () => {
      if (containerRef.current)
        observer.unobserve(containerRef.current)
    }
  }, [])
  return (
    <div
      ref={containerRef}
      className="relative flex flex-col justify-center items-center gap-2rem w-1146px mx-auto my-10rem mb-42rem <lg:mb-137 <lg:w-900px <md:w-732px <md:mb-30rem <md:mt-55 <sm:w-90% <sm:max-w-380px <sm:gap-3.4rem <sm:mb-20rem <sm:mt-40"
    >
      <MaskedTitle
        title="Let Pheno take your digital vision to new heights with our expert team"
        titleClassName="text-40px font-700 font-Montserrat leading-56px text-hex-0B2864 text-center <lg:text-32px <lg:leading-40px <sm:text-24px <sm:leading-33px <sm:w-100%"
        className="w-67% <lg:w-70% <md:w-76% <sm:w-100%"
        logoClassName='!-top-14px !-left-38px <lg:!-left-13px <md:!-top-22px <md:!left-4px <sm:!-top-22px <sm:!left-9px'
      />
      <div className="flex justify-between items-center w-95% <md:w-100%  <sm:flex-col <sm:self-start <sm:hidden">
        {breakpoint !== 'mobile' && digitalVision.map((item, i) => {
          return (
            <p key={i}
              className={`flex justify-center items-center font-Montserrat h-22px text-hex-445069 text-center <md:h-40px transition-all duration-500 ease-in-out ${current === i ? 'font-700 text-14px <lg:text-12px' : 'text-12px <lg:text-10px font-500'} ${item.headerStyles}`}
            >
              {item.header}
            </p>
          )
        })}
      </div>
      <img
        src="/layers/grid-layer.svg"
        alt="grid layer"
        className="absolute -z-2 left-50% top-12.5rem transform -translate-x-50% <lg:top-9.5rem <md:top-10.5rem <sm:relative <sm:hidden"
      />
      {breakpoint !== 'mobile' && digitalVision.map((item, i) => {
        return (

          <div
            key={i}
            className={`absolute ${item.containerStyles}`}
          >
            <div
              onMouseEnter={() => {
                setCurrent(i)
              }}
              onMouseLeave={() => {
                setCurrent(null)
              }}
              className={`flex items-center z-1 ${item.delay} ${item.titleBackground} origin-left transition-all duration-1000 ease-in-out ${!show ? 'h-44px p-6px <lg:h-36px justify-center rounded-22px <md:h-32px' : 'h-auto px-15px py-6px justify-between rounded-17px <lg:rounded-12px <md:rounded-10px <sm:px-12px <lg:py-5px'}`}
            >
              <p
                className={`text-12px font-700 font-Montserrat text-hex-445069 <lg:text-10px overflow-hidden transition-all duration-1000 ease-in-out origin-left ${item.delay} ${!show ? 'max-w-0 text-ellipsis line-clamp-0' : 'max-w-24rem text-ellipsis line-clamp-1'}`}
              >
                {item.title}
              </p>
              <span
                className={`w-32px h-32px rounded-full bg-white <lg:w-24px <lg:h-24px <md:w-20px <md:h-20px transition-all duration-1000 ease-in-out ${item.delay} ${show ? 'ml-34px <lg:ml-13px' : 'ml-0px'}`}
              ></span>
            </div>
            <div
              className={`flex justify-center gap-10px absolute z-1 ${item.descriptionStyles} ${current === i ? 'opacity-100 scale-100 ' : 'opacity-0 scale-0'} transition-all duration-500 ease-in-out`}
            >
              <ArrowSvg className={`${item.lottieStyles} SVG !w-3rem !h-3rem <md:!w-2.5rem z-10  ${current === i ? 'opacity-100 scale-100 ' : 'opacity-0 scale-0 fill-none'} transition-all duration-500 ease-in-out`} />

              <div className={`flex items-start gap-6px ${current === i ? 'opacity-100 scale-100 ' : 'opacity-0 scale-0 '} transition-all duration-500 ease-in-out delay-500`}>
                <p
                  className="text-64px font-400 text-hex-FFE1E1 font-number <lg:text-48px"
                >
                  {item.number}
                </p>
                <p
                  style={{ boxShadow: ' 0px 0px 25px 0px rgba(68, 68, 68, 0.12)', border: '1.412px solid #E9EEF4' }}
                  className="rounded-22px bg-white p-16px text-hex-445069 text-16px font-400 font-Montserrat leading-24px w-380px <lg:text-12px <lg:rounded-17px <lg:p-12px <lg:w-290px <md:text-12px <md:leading-15px"
                >
                  {item.description}
                </p>
              </div>
            </div>
          </div>
        )
      })}
      {breakpoint === 'mobile' && <DigitalVisionMobile show={show} />}
    </div>
  )
}
